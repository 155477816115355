"use client";

import { XCircleIcon } from "lucide-react";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Loader } from "./ui/loader";

export default function AuthWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loaded, setLoaded] = useState(false);
  const [key, setKey] = useState("");

  const [disclaimerHidden, setDisclaimerHidden] = useState(false);
  const [shouldShowDisclaimer, setShouldShowDisclaimer] = useState(false);

  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (window.self !== window.top && !pathname.startsWith("/maintenance")) {
      window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
      window.addEventListener("message", (event) => {
        if (event.data.message === "REQUEST_USER_DATA_RESPONSE") {
          setKey(event.data.payload);
        }
      });
    } else {
      if (
        !pathname?.startsWith("/auth/oauth") &&
        !pathname?.startsWith("/auth/cookies") &&
        !pathname?.startsWith("/iframe") &&
        !pathname?.startsWith("/maintenance")
      ) {
        fetch("/auth/logout", {
          method: "POST",
        }).then(() => {
          router.push("/");
          setLoaded(true);
        });
      } else {
        setLoaded(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!key) return;
    fetch("/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ssoToken: key }),
    }).then((res) => {
      res.json().then((data) => {
        if (pathname.startsWith("/contacts") && data.userChanged) {
          window.location.reload();
        } else {
          setLoaded(true);
          setShouldShowDisclaimer(
            data.success &&
              data.scopes.length > 0 &&
              !data.scopes?.includes("locations/customFields.readonly"),
          );
          if (
            pathname == "/embed" ||
            (!data.setupComplete &&
              data.redirect &&
              !pathname.startsWith("/contacts"))
          ) {
            router.push(data.redirect);
          }
        }
      });
    });
  }, [key]);

  return (
    <>
      {loaded ? (
        <>
          {children}
          {shouldShowDisclaimer ? (
            disclaimerHidden ? null : (
              <div className="bg-primary text-primary-foreground fixed bottom-0 w-full py-4 text-center">
                Your application permissions are out of date. Please have your
                agency reinstall the application to update your permissions, and
                restore full functionality.
                <XCircleIcon
                  className="static right-0 ml-2 inline-block h-6 w-6 cursor-pointer"
                  onClick={() => setDisclaimerHidden(true)}
                />
              </div>
            )
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
